// source sans Pro
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

/* 
1366×768 (ordenador portátil medio): 11,98%. 
1920×1080 (escritorio grande): 7,35%. 
*/

// Media Queries size

$desktopXS: 1024px;
$desktopSTD: 1366px;

// Vars
$primary: #f9f9f9;
$blue: #0b149d;
$textBlue: #7d97f4;
$darkBlue: #003366;
$incorrect: #ca323e;
$green: #00c269;
$greenLight: #57d69b;
$white: #fff;
$input-focus: #7d97f4;
$gray01: #c4c4c4;
$gray02: #888888;
$gray03: #f2f2f2;
$transition: all 0.2s;

//inputs
$input-border-radius: 2rem;
$border-size: 1px solid;
$input-padding: 1rem;

// Font
$text: "Source Sans Pro", sans-serif;

$breakpoints: (
    "sm": 425px,
    "md": 768px,
    "lg": 960px,
    "xl": 1250px,
    "xxl": 1600px,
);

// Animation global

@keyframes rotateSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@mixin from($size) {
    @media screen and (min-width: map-get($breakpoints, $size)) {
        @content;
    }
}

@mixin Formcontainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    width: 90vw;

    @include from("md") {
        width: 450px;
        margin-top: 0;
    }
}

@mixin FormTitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: $blue;
    letter-spacing: -1px;
    text-align: center;
    padding: 2rem 0 3rem 0;
    line-height: 1.5;
}

@mixin FormSubtitle {
    color: $gray02;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: $textBlue;
    margin: 1rem 0;
    display: block;
}

.label {
    font-size: 0.9rem;
    color: #888888;
    margin: 0.5rem 0 .5rem 1rem;
    display: inline-block;
    text-transform: capitalize;
    width: 100%;
    .informtationText { 
        font-size: 0.8rem;
        color: $input-focus;
        margin-left: .5rem;
    }
}

.subtitle {
    // display: flex;
    // align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: #0b149d;
    text-transform: capitalize;
    margin: 0 0 0.5rem 1rem;
    width: 100%;
    font-style: italic;
    
}

.pillsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.errorText {
    color: $incorrect;
    font-size: 1.2rem;
    text-align: center;
    padding: 0.5rem;
}

.loadingIcon {
    font-size: 4rem;
    color: #00c269;
    animation: rotateSpinner 1s infinite;
}