* {
  padding: 0;
  margin: 0;
  border: 0;
  font-display: swap;
  font: inherit;
  color: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  scroll-behavior: smooth;
  line-height: 1.2;
  outline: none;
  scroll-behavior: smooth;
  scrollbar-color: #878e99 transparent;
  scrollbar-width: thin;
  font-family: 'Source Sans Pro', sans-serif;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  display: none;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

*:link {
  text-decoration: none;
}

*:active {
  border: none;
  outline: none;
}

ul {
  list-style: none;
}

* {
  box-sizing: border-box;
}