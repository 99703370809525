@import '../general';

.containerFallback {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    
    .loadingIcon {
        font-size: 4rem;
        color: #00c269;
        animation: rotateSpinner 1s infinite;
    }
}
